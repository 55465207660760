import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import "../../App.css"
import Cookies from 'js-cookie';
import ResetPasswordForm from '../../Components/Forms/ResetPasswordForm'
import i18n from '../../util/i18n';

const useStyles = makeStyles(() => ({
    logo: {
        maxWidth: 130
    },
    wrapper: {
        display: "flex",
        width: "100%",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 30,

    },
}))

const ResetPasswordPage = () => {
  const classes = useStyles();
  const [lang, setLang] = useState('en');

  const ChangeLanguageHandler = (e) => {
    if(i18n.language === 'en') {
      i18n.changeLanguage('ar').then(() => {
        setLang('ar')
        Cookies.set('i18next', 'ar');
      })
    } else {
      i18n.changeLanguage('en').then(() => {
        setLang('en')
        Cookies.set('i18next', 'en');
      })
    }
  }

  useEffect(() => {
    if (!Cookies.get('i18next')) {
        Cookies.set('i18next', 'en');
    }
  }, []);

  return (
    <Box >
      <div className={classes.wrapper}>
        <img className={classes.logo} src={"/assets/images/Basmacom Logo"} alt="Basma-logo" />
        <p style={{ cursor: 'pointer' }} onClick={ChangeLanguageHandler} className="language">{lang === 'ar' ? 'EN' : 'عربى'}</p>
      </div>
      <ResetPasswordForm />
    </Box>
  )
}

export default ResetPasswordPage
