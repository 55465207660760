import React, { useContext, useState } from "react";
import axios from "../../util/axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import Layout from "../TopNav/Topnav";
import {
  Box,
  Card,
  FormControl,
  makeStyles,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  FormHelperText,
  CircularProgress
} from "@material-ui/core";
import _, { unset } from 'lodash'
import { email, firstName, language, lastName, GlobalCountries } from "../../util/validation";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {Alert, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsControllerContext } from "../../Context/Tabs/TabsProvider";

const useStyles = makeStyles(() => {
  return {
    textField: {
      width: "49%",
      ['@media only screen and (max-width: 1200px) ']: {
        width: '100%'
      },
    },
    card: {
      padding: '5%',
      display: 'grid',
      gap: '20px',
      width: '98%',
      margin: '0 auto',
      marginTop: '20px',
    },
    alertMargin: {
      width: '100%',
      textAlign: "center",
      margin: '0 auto',
      whiteSpace: 'pre-line'
    },
    box: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '15px',
      rowGap: '25px',
      marginTop: "5px"
    },
    headerBox: {
      textAlign: 'right',
      marginTop: '20px',
    },
    cancel: {
      width: '78px',
      height: '36px',
      borderRadius: '3px',
      fill: 'Linear gradient, 180°',
      border: '1px #F9FAFB solid',
      boxShadow: '0px 1px 5px lightgray',
      margin: '5px',
    },
    save: {
      width: '78px',
      height: '36px',
      borderRadius: '3px',
      fill: 'Linear gradient, 180°',
      backgroundColor: '#FF2C46',
      color: 'white',
      margin: '5px',
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#fff",
    },
  }
})

const AddPatient = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({})
  const {
    clinicNotifications,
  } = useContext(TabsControllerContext);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setMessage('')
  }
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value);
    const validEmail = email(e.target.value)
    if (!validEmail) {
      setErrors({
        ...errors,
        email: 'Invalid Email'
      })
    } else {
      unset(errors, 'email')
    }
  }
  if (openAlert) {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
  }
  const [firstNameValue, setFirstNameValue] = useState("");
  const handleChangeFirstName = (e) => {
    setFirstNameValue(e.target.value);
    const validFirstname = firstName(e.target.value);
    if (!validFirstname) {
      setErrors({
        ...errors,
        firstname: 'Invalid first name'
      })
    } else {
      unset(errors, 'firstname')
    }
  }
  const [lastNameValue, setLastNameValue] = useState("");
  const handleChangeLastName = (e) => {
    setLastNameValue(e.target.value);
    const validLastname = lastName(e.target.value)
    if (!validLastname) {
      setErrors({
        ...errors,
        lastname: 'Invalid last name'
      })
    } else {
      unset(errors, 'lastname')
    }
  }
  const [personalMobileNumberValue, setPersonalMobileNumberValue] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState('');
  const handleChangePersonalMobileNumber = (e) => {
    setPersonalMobileNumberValue(e);
    if (e === undefined || e === "") {
      setErrors({
        ...errors,
        phone: t("phonenbrrequired"),
      })
    } else if (!isValidPhoneNumber(e)) {
      setErrors({
        ...errors,
        phone: t("validphonenumber"),
      })
    } else {
      unset(errors, 'phone')
    }
  }
  const handleChangeWhatsAppMobileNumber = (e) => {
    setWhatsAppNumber(e);
  }
  const [selectedLanguage, setSelectedLanguage] = useState("0");
  const handleSelectChangeLanguage = (e) => {
    setSelectedLanguage(e.target.value)
    const validLanguage = language(e.target.value)
    if (!validLanguage) {
      setErrors({
        ...errors,
        language: 'Invalid language'
      })
    } else {
      unset(errors, 'language')
    }
  }
  const handleCancel = () => {
    navigate('/dashboard')
  }

  return (
    <Layout hideCreate={true}>
      <Card className={classes.card}>
        <Typography variant="h6">{t('add-patient-title')}</Typography>
        {openAlert && <Alert open={openAlert} className={classes.alertMargin} severity="error" onClose={handleCloseAlert}>
          {message}
        </Alert>}
        <Box className={classes.box}>
          <TextField className={classes.textField}
            label={t("First-Name")}
            required={true}
            value={firstNameValue}
            error={errors.firstname}
            helperText={errors.firstname}
            onChange={(e) => handleChangeFirstName(e)}
          />
          <TextField className={classes.textField}
            label={t("Last-Name")}
            required={true}
            value={lastNameValue}
            error={errors.lastname}
            helperText={errors.lastname}
            onChange={(e) => handleChangeLastName(e)}
          />
          <TextField className={classes.textField}
            value={emailValue}
            label={t("Email-Address")}
            required={true}
            onChange={(e) => handleChangeEmail(e)}
            error={errors.email}
            helperText={errors.email}
            placeholder={t("Email-Address")}
          />
          <div className='add-patient-input-wrapper'>
            <PhoneInput
              className='add-patient-input-text-phone'
              countries={GlobalCountries}
              rules={{ required: true }}
              flagUrl="../../../flags/{xx}.svg"
              placeholder={t("Personal-Mobile-Number")}
              value={personalMobileNumberValue}
              onChange={(e) => handleChangePersonalMobileNumber(e)}
              defaultCountry={clinicNotifications?.country?.code}
            />
            {errors?.phone && <FormHelperText error={true}>{errors.phone}</FormHelperText>}
          </div>
          <div className='add-patient-input-wrapper'>
            <PhoneInput
              className='add-patient-input-text-phone'
              countries={GlobalCountries}
              flagUrl="../../../flags/{xx}.svg"
              placeholder={t('whatsapp-Mobile-Number')}
              value={whatsAppNumber}
              onChange={(e) => handleChangeWhatsAppMobileNumber(e)}
              defaultCountry={clinicNotifications?.country?.code}
            />
          </div>
          <FormControl className={classes.textField}>
            <InputLabel
              required={true}
              error={errors.language}
              id="demo-simple-select-standard-label">{t('Select-Language')}</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              placeholder="Please Select"
              value={selectedLanguage}
              error={errors.language}
              required={true}
              onChange={(e) => {
                handleSelectChangeLanguage(e)
              }}
              label={t("Age")}
            >
              <MenuItem value="">{t('Please-Select')}</MenuItem>
              <MenuItem value="en">{t('English')}</MenuItem>
              <MenuItem value="ar">{t('Arabic')}</MenuItem>
            </Select>
            {errors.language && <FormHelperText error={true}>{errors.language}</FormHelperText>}
          </FormControl>
        </Box>
        <Box className={classes.headerBox}>
          <Button className={classes.cancel} onClick={handleCancel}>{t('Cancel')}</Button>
          <Button className={classes.save} disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={24} className={classes.loading} /> : t('Save')}
          </Button>
        </Box>
      </Card>
    </Layout>
  );

  function handleSave() {
    const bodyObj = {
      ...(firstNameValue && { first_name: firstNameValue }),
      ...(lastNameValue && { last_name: lastNameValue }),
      ...(emailValue && { email: emailValue }),
      ...(personalMobileNumberValue && {
        personal_mobile_number: personalMobileNumberValue,
      }),
      ...(whatsAppNumber && {
        whatsapp_number: whatsAppNumber,
      }),
      options: {
        language: selectedLanguage,
      },
      clinicAdded: true,
      patientType: "Clinic Referred Patient",
      timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (!_.isEmpty(errors) || firstNameValue === '' || lastNameValue === '' || emailValue === '' || personalMobileNumberValue === '' || selectedLanguage === '') {
      window.scroll({
        top: 0,
        behavior: "smooth"
      })
      setOpenAlert(true)
      setMessage('Missing fields')
    } else {
      setLoading(true);
      axios
        .post("admin/v1/users/clinic", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("access_token_clinic"),
          },
        }).then((res) => {
          if (res?.data?.errors) {
            let message = '';
            Object.keys(res?.data?.errors).map((field) => {
              res?.data?.errors[field].map((error) => {
                message += `${error} \n`
                return error;
              });
              return field
            });
            setMessage(message);
            setOpenAlert(true);
            return;
          }
          if (res?.data?.success === false) {
            let message = '';
            if (res?.data?.message) {
              message = res?.data?.message;
              setMessage(message);
              setOpenAlert(true);
              return;
            }
          }
          setOpenAlert(false)
          setMessage('')
          let userId = res?.data?.user_id;
          let appointmentId = res?.data?.appointment_id;
          setTimeout(() => {
            navigate(`/dashboard/users/${userId}/appointments/${appointmentId}/edit-patient-data`);
          }, 200)
        }).catch((err) => {
          setOpenAlert(true);
          console.log('Error in Creating a user', err);
          setMessage('Something Wrong Happened, Please contact Basma Support');
        }).finally(() => setLoading(false));
    }
  }
};

export default AddPatient;
