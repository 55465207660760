import React, { useEffect, useState } from 'react';
import { Grid, Paper, TextField, Button, Typography, makeStyles, Box } from '@material-ui/core'
import axios from '../../util/axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5%',
            height: '55vh',
            width: '40vw',
            margin: "20px auto",
            boxShadow: " 0 0 0 4px rgba(63,63,68,0.05), 0 1px 3px 2px rgba(63,63,68,0.15)",
            [theme.breakpoints.between('sm', 'md')]: {
                height: '55vh',
                width: '45vw',
            },
            [theme.breakpoints.down('sm')]: {
                height: '55vh',
                width: '55vw',
            },
            ['@media(max-width: 600px)']: {
                height: '55vh',
                width: '80vw',
            },
            ['@media(min-height: 1000px)']: {
                height: '50vh',
            }
        },
        btn: {
            margin: '20px 0 '
        },
        field: {
            marginBottom: 20,

        },
        bigTitle: {
            fontWeight: 'bold',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h4.fontSize,

            },
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.h5.fontSize,
            },
        },
        smallTitle: {
            fontSize: '16px',
        },
        emailSent: {
            color: 'green',
        },
        error: {
            color: 'red'
        },
        footerTitle: {
            color: 'white',
            backgroundColor: '#FF2C46',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h5.fontSize,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1em',
            },
            ['@media(max-width: 600px)']: {
                fontSize: '0.8em',
            },
            ['@media(max-width: 300px)']: {
                fontSize: '0.7em',
            }
        },
        shapeBox: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -3,
            left: 0,
            right: 0
        },
        rect: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -2,
            left: 0,
            right: 0,
            clipPath: "inset(50% 0 0 0)",
            height: '100%',
            backgroundColor: '#FF2C46',
        },
        forgotPass: {
            color: '#8cb6ff',
            fontWeight: 400,
            fontSize: 14
        },
        ellipse: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -1,
            left: 0,
            right: 0,
            clipPath: "ellipse(58% 12% at 50% 56%)",
            height: '100%',
            backgroundColor: 'white',
        }
    }
})

const ResetPasswordForm = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const query = new URLSearchParams(document.location.search);
    const token = query.get('token');
    const email = query.get('email');
    const [isSuccess, setIsSuccess] = useState("");
    const [errorMsg, setErrorMsg] = useState('');
    const classes = useStyles();
    const [password, setPassword] = useState({
        new: '',
        confirmed: '',
    });

    useEffect(() => {
        axios.get("admin/v1/password/find/" + token, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    function submitForm(e) {
        e.preventDefault();
        setErrorMsg('');
        if(!password.new || !password.confirmed) {
            setErrorMsg('required-fields');
            return;
        }
        if(password.new != password.confirmed) {
            setErrorMsg('password-mismatch');
            return;
        }
        const bodyObj = {
            email,
            token,
            password: password.new,
            password_confirmation: password.confirmed,
        };
        axios.post("/admin/v1/password/reset", bodyObj, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then(() => {
            navigate('/');
        }).catch((err) => console.log(err));
    }

    return (
        <Grid >
            <Paper className={classes.paper}>
                <Grid align="center" style={{ marginBottom: "15px" }}>
                    <Typography variant="h3" className={classes.bigTitle} gutterBottom>{t("SMILE TOGETHER")}</Typography>
                    <Typography variant="p" className={classes.smallTitle}>{t("Forgot-password-content")}</Typography>
                </Grid>
                <form onSubmit={(e) => { submitForm(e) }}>
                    <TextField label={t('password')} placeholder={t('enter-password')} variant="standard" type='password' fullWidth className={classes.field} value={password.new} onChange={(e) => setPassword(prev => ({...prev, new: e.target.value}))} />
                    <TextField label={t('confirm-password')} placeholder={t('enter-confirmed-password')} variant="standard" type='password' fullWidth className={classes.field} value={password.confirmed} onChange={(e) => setPassword(prev => ({...prev, confirmed: e.target.value}))} />
                    {errorMsg && <Typography align='center' variant='body1' className={classes.error}>{t(errorMsg)}</Typography>}
                    {/* {errorMsg && <Typography variant="p" className={classes.emailSent}>{t(errorMsg)}</Typography>} */}
                    <Button type='submit' color='secondary' variant="contained" fullWidth className={classes.btn} >{t('submit')}</Button>
                </form>
            </Paper>
            <Paper elevation={0} style={{ backgroundColor: 'transparent' }}>
                <Typography align='center' variant='h5' className={classes.footerTitle} >{t('LET’S-MAKE-THE-WORLD-SMILE-MORE-TOGETHER.')}</Typography>
            </Paper>
            <Box className={classes.shapeBox} >
                <div className={classes.rect}></div>
                <div className={classes.ellipse}></div>
            </Box>
        </Grid>
    );
}

export default ResetPasswordForm
