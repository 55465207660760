import React, { useState, useEffect } from "react";
import { FormLayout, Card, Layout, TextStyle, Button } from "@shopify/polaris";
import axios from "../../../util/axios";
import { useNavigate, useParams } from "react-router-dom";
import Model3dPart from "./Model3d";
import '../../../styles/_3dmodel.css'
import IframeViewer from "./IframeViewer";

function YourSmilePlan(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const show_new_viewer = process.env.REACT_APP_NEW_VIEWER;
  const [yourSmilePlanResponse, setYourSmilePlanResponse] = useState([]);
  useEffect(async() => {
    const ac = new AbortController()
   try {
    const result = await axios.get("admin/v1/users/" + id + "/smile-plan/preferred")
        setYourSmilePlanResponse(result.data.data);
   }catch(err) { console.log("error=", err)};
   return () => ac.abort();
  }, []);
  return (
    //  <Layout.Section>
    //     <Card sectioned title="YOUR SMILE PLAN">
    //     {yourSmilePlanResponse && (
    //       <FormLayout.Group>
    //         <FormLayout>
    //           {yourSmilePlanResponse.guide_created_at && (
    //             <TextStyle>
    //               TP Created at : {yourSmilePlanResponse.guide_created_at}
    //             </TextStyle>
    //           )}
    //           {yourSmilePlanResponse.guide_updated_at && (
    //             <TextStyle>
    //               TP Updated at : {yourSmilePlanResponse.guide_updated_at}
    //             </TextStyle>
    //           )}

    //           {yourSmilePlanResponse.number_of_aligner && (
    //             <TextStyle>
    //               Number of steps : {yourSmilePlanResponse.number_of_aligner}
    //             </TextStyle>
    //           )}
    //           {yourSmilePlanResponse.video_id && (
    //             <TextStyle>
    //               Video ID 1 : {yourSmilePlanResponse.video_id}
    //             </TextStyle>
    //           )}
    //           {yourSmilePlanResponse.video_id_2 && (
    //             <TextStyle>
    //               Video ID 2 : {yourSmilePlanResponse.video_id_2}
    //             </TextStyle>
    //           )}
    //           {yourSmilePlanResponse.three_dimension_smile && (
    //             <TextStyle>
    //               {yourSmilePlanResponse.three_dimension_smile.length} 3D files
    //               are uploaded
    //             </TextStyle>
    //           )}
    //           {yourSmilePlanResponse.number_of_aligner_upper !== null &&
    //             yourSmilePlanResponse.number_of_aligner_upper !== undefined && (
    //               <TextStyle>
    //                 Number of steps - Upper :{" "}
    //                 {yourSmilePlanResponse.number_of_aligner_upper}
    //               </TextStyle>
    //             )}
    //           {yourSmilePlanResponse.number_of_aligner_lower !== null &&
    //             yourSmilePlanResponse.number_of_aligner_lower !== undefined && (
    //               <TextStyle>
    //                 Number of steps - Lower :{" "}
    //                 {yourSmilePlanResponse.number_of_aligner_lower}
    //               </TextStyle>
    //             )}

    //           {yourSmilePlanResponse.number_of_refinement_aligner_upper !==
    //             null &&
    //             yourSmilePlanResponse.number_of_refinement_aligner_upper !==
    //               undefined && (
    //               <TextStyle>
    //                 Number of Refinement Steps - Upper :{" "}
    //                 {yourSmilePlanResponse.number_of_refinement_aligner_upper}
    //               </TextStyle>
    //             )}
    //           {yourSmilePlanResponse.number_of_refinement_aligner_lower !==
    //             null &&
    //             yourSmilePlanResponse.number_of_refinement_aligner_lower !==
    //               undefined && (
    //               <TextStyle>
    //                 Number of Refinement Steps - Lower :{" "}
    //                 {yourSmilePlanResponse.number_of_refinement_aligner_lower}
    //               </TextStyle>
    //             )}
    //           {yourSmilePlanResponse.treatment_length && (
    //             <TextStyle>
    //               {yourSmilePlanResponse.treatment_length} month treatement
    //             </TextStyle>
    //           )}

    //           <TextStyle>
    //             IPR added :{" "}
    //             {yourSmilePlanResponse.ipr_is_active &&
    //             yourSmilePlanResponse.ipr_url
    //               ? "Yes"
    //               : "No"}
    //           </TextStyle>
    //           <TextStyle>
    //             IPR added :{" "}
    //             {yourSmilePlanResponse.ipr_is_active &&
    //             yourSmilePlanResponse.ipr_url
    //               ? "Yes"
    //               : "No"}
    //           </TextStyle>
    //           {yourSmilePlanResponse.ipr_before_step_number != null && (
    //             <TextStyle>
    //               Before Step Number :{" "}
    //               {String(yourSmilePlanResponse.ipr_before_step_number)}
    //             </TextStyle>
    //           )}

    //           {yourSmilePlanResponse.designer_name && (
    //             <TextStyle>
    //               Treatment Plan Designer name :{" "}
    //               {yourSmilePlanResponse.designer_name}
    //             </TextStyle>
    //           )}
    //         </FormLayout>
    //         <FormLayout>
    //           <Button
    //             primary
    //             id={yourSmilePlanResponse.guide_url}
    //             onClick={(e) => openFile(e)}
    //             disabled={!yourSmilePlanResponse.guide_url && true}
    //           >
    //             View Treatment Plan
    //           </Button>
    //           <Button
    //             primary
    //             disabled={
    //               (yourSmilePlanResponse?.three_dimension_smile?.length == 0 ||
    //                 !yourSmilePlanResponse?.three_dimension_smile) &&
    //               true
    //             }
    //             onClick={() =>
    //               navigate(
    //                 `/dashboard/users/${id}/yoursmileplan/3d-viewer`
    //               )
    //             }
    //           >
    //             View 3D Viewer
    //           </Button>
    //           <Button
    //             primary
    //             id={yourSmilePlanResponse.ipr_url}
    //             disabled={
    //               (!yourSmilePlanResponse.ipr_url ||
    //                 !yourSmilePlanResponse.ipr_is_active) &&
    //               true
    //             }
    //             onClick={(e) => openFile(e)}
    //           >
    //             View IPR sheet
    //           </Button>
    //         </FormLayout>
    //       </FormLayout.Group>
    //     )}
    //   </Card>
    //  </Layout.Section>
    <>
    <div className='model3d'>
        {!show_new_viewer ?
          <Model3dPart models={yourSmilePlanResponse.three_dimension_smile_url} /> :
          <IframeViewer planId={yourSmilePlanResponse.id} />
      }
    </div>
  </>
  );
  function openFile(e) {
    var win = window.open(e.currentTarget.id, "_blank");
    win.focus();
  }
}
export default React.memo(YourSmilePlan);
