import React, { Suspense, useState } from 'react'
import './styles/styles.css'
import './styles/information_styles.css'
import CalendarPage from './Pages/calendarPage/CalendarPage'
import LoginPage from './Pages/loginPage/LoginPage'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import "./App.css"
import { ThemeProvider } from '@material-ui/core';
import theme from './lib/theme';
import UserProfile from './Components/Users/UserProfile';
import { AppProvider } from "@shopify/polaris";
import '@shopify/polaris/build/esm/styles.css';
import AddFile from './Components/ProfileComponents/Files/AddFile';
import AddComment from './Components/ProfileComponents/Comments/Add';
import EditComment from './Components/ProfileComponents/Comments/Edit';
import EditFile from './Components/ProfileComponents/Files/EditFile';
import YourSmilePlan from './Components/ProfileComponents/Yoursmileplan/YourSmilePlan';
import ActiveContext from './Context/Context';
import BookingLanding from './Pages/Book-a-scan/booking-landing';
import ScanConfirmLoading from './Pages/Book-a-scan/scan-cnfm-loading';
import AddPhotoIdImages from './Components/UserPhotoId/AddPhotoIdImages';
import UserPhotoIdProvider from './Context/UserPhotoId/UserPhotoIdProvider';
import TabsProvider, { TabsControllerContext } from './Context/Tabs/TabsProvider';
import EditPatientData from './Components/Patientdata/EditPatientData';
import EditScan from './Components/ProfileComponents/Scans/EditScan';
import Schedule from './Pages/Book-a-scan/Schedule_landing';
import Impersonate from './Pages/Impersonate/Impersonate';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Billing from "./Pages/billing/Billing";
import InstructionsPage from "./Pages/Instructions/InstructionsPage";
import EditAppointmentPatientData from "./Components/Patientdata/edit-appointment-patient-data.component";
import Patient from './Components/AddPatient/Patient';
import Booking_Calendar from "./Pages/Book-a-scan/Booking_Calendar";
import SignUpPage from './Pages/SignUpPage/SignUpPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage/ForgotPasswordPage'
import ResetPasswordPage from './Pages/ResetPasswordPage/ResetPasswordPage'

function App() {
  const [active, setActive] = React.useState(null);
  const profileId = 'profileId'
  const statusId = 'statusId'
  const scansId = 'scansId'
  const screeningId = 'screeningId'
  const impressionsandphotosId = 'impressionsandphotosId'
  const filesId = 'filesId'
  const smilePlanId = 'smilePlanId'
  const firsttimeId = 'firsttimeId'
  const commentsId = 'commentsId'
  const [clinicData, setClinicData] = useState(null);
  const [loading, setLoading] = useState(true)
  const [imagesArray, setImagesArray] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxisOpen, setLightboxIsOpen] = useState(false);
  const [filesImage,setFilesImage] =useState([])
  const [imagesKey,setImageKey] =useState([])
  const [images,setImages] =useState([])
  const handleShowLargeImage=(images,key)=>{
    setLightboxIsOpen(true)
    setPhotoIndex(key)
    setImagesArray(images)
  }
  const handleCloseLargeImage=(images,key)=>{
    setLightboxIsOpen(false)
    setPhotoIndex(0)
    setImagesArray([])
  }
  
  return (
    <AppProvider>
      <Suspense fallback={() => <div>Loading...</div>}>
      <ThemeProvider theme={theme}>
       <ActiveContext.Provider value={{clinicData,loading,setLoading, setClinicData,active, setActive, profileId, statusId, scansId, screeningId, impressionsandphotosId,
        filesId, smilePlanId, firsttimeId, commentsId,imagesArray,photoIndex,handleShowLargeImage,setPhotoIndex,setImagesArray,lightboxisOpen,setLightboxIsOpen,filesImage,setFilesImage,imagesKey,setImageKey,images,setImages,handleCloseLargeImage
      }}>
       <BrowserRouter>
       <TabsProvider>
       <UserPhotoIdProvider>
       <ToastContainer />
        <Routes>
          <Route path="/" element={<LoginPage />}/>
          <Route path="/sign-up" element={<SignUpPage />}/>
          <Route path="/forgot-password" element={<ForgotPasswordPage />}/>
          <Route path="/password/reset" element={<ResetPasswordPage />}/>
          <Route path="/dashboard" element={< >
          <CalendarPage/> </>}
          />
            <Route
            path="/impersonate"
            element={<><Impersonate/></>}
          />
            <Route
            path="/dashboard/users/:id"
            element={<><UserProfile/></>}
          />
            {/* <Route
            path="/dashboard/users/:id/addscan"
            element={<><AddScan/></>}
            /> */}
            <Route
            path="/dashboard/users/:id/editscan/:documentId"
            element={<><EditScan/></>}
            />
          <Route
            path="/dashboard/users/:id/addPhotoId"
            element={<><AddPhotoIdImages 
                          photoTitle1={'Front-of-ID'} 
                          photoTitle2={'Back-of-ID'} 
                          key1={'photo_front_id'} 
                          key2={'photo_back_id'} 
                          title={'Customer ID Photo'} 
                          api={'photoId'} /></>}
          />
          <Route
            path="/dashboard/users/:id/memberShipId"
            element={<><AddPhotoIdImages 
                          photoTitle1={'membership-img-one'} 
                          photoTitle2={'membership-img-two'} 
                          key1={'membership_image_one'} 
                          key2={'membership_image_two'} 
                          title={'Membership ID Photo'} 
                          api={'membershipId'}/></>}
          />
          <Route path="/dashboard/billing" element={<Billing/>}/>
            {/* <Route
            path="/dashboard/users/:id/editscreening"
            element={<><EditScreening/></>}
          /> */}
            {/* <Route
            path="/dashboard/users/:id/editimpressionsandphotosstep3"
            element={<><EditImpressionsAndPhotosStep3/></>}
          /> */}
            {/* <Route
            path="/dashboard/users/:id/editimpressionsandphotosstep4"
            element={<><EditImpressionsAndPhotosStep4/></>}
          /> */}
          <Route
            path="/dashboard/users/:id/new-file/:type"
            element={<><AddFile/></>}
          />
           <Route
            path="/dashboard/users/:id/appointments/:appointmentId/edit-patient-data"
            element={<EditAppointmentPatientData/>}
          />
          <Route
            path="/dashboard/users/:id/edit-patient-data"
            element={<EditPatientData/>}
          />
            <Route
            path="/dashboard/users/:id/addcomment"
            element={<><AddComment/></>}
          />
            <Route
            path="/dashboard/users/:id/notifications"
            element={<><EditComment/></>}
          />
            <Route
            path="/dashboard/users/:id/files/:fileId/:type"
            element={<><EditFile/></>}
          />
            <Route
            path="/dashboard/users/:id/yoursmileplan/3d-viewer"
            element={<><YourSmilePlan/></>}
          />
            {/* <Route
            path="/dashboard/users/:id/redo-scan/:scanId"
            element={<><RedoScan/></>}
          /> */}
            <Route
            path="/dashboard/booking"
            element={<Booking_Calendar/>}
          />
            <Route
            path="/en/scan-cnfm-loading"
            element={<><ScanConfirmLoading/></>}
          />
          <Route
            path="/dashboard/patient/add"
            element={<><Patient/></>}
          />
            <Route path="/dashboard/instructions" element={<InstructionsPage/>}/>

          </Routes>
          </UserPhotoIdProvider>
          </TabsProvider>
          </BrowserRouter>
       </ActiveContext.Provider>
          </ThemeProvider>
      </Suspense>
      </AppProvider>
  );
}

export default App;
