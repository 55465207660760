import Layout from "../../Components/TopNav/Topnav";
import { makeStyles } from "@material-ui/core";
import theme from "../../lib/theme";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import FirstVisitAppointment from "./first-visit-appointment.component";
import FaqLearnMore from "./faq-learn-more.component";
import TabNav, { TabPanel } from "../../Components/TabNav/Tabnav";

const useStyles = makeStyles(() => ({
  boxWrap: {
    padding: 25,
    backgroundColor: theme.palette.background.body,
    width: "800px",
    margin: "0 auto",
  },
}));

const InstructionsPage = () => {
  const [isArabic, setIsArabic] = useState(false);
  const classes = useStyles();
  let align = isArabic ? "right" : "left";
  const { t, i18n } = useTranslation();

  const tabNavProps = {
    tabs: [
      {
        id: 0,
        label: t("basma-first-visit-in-the-clinic"),
      },
      {
        id: 1,
        label: t("faq-header"),
      },
    ],
  };

  const unorderedListStyle = isArabic
    ? {
        margin: "10px 20px 0 0",
        color: "#000000",
        fontWeight: "normal",
      }
    : {
        margin: "10px 0 0 20px",
        color: "#000000",
        fontWeight: "normal",
      };

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  return (
    <Layout isProfile={true} hideHeader={true}>
      <div className={classes.boxWrap}>
        <TabNav {...tabNavProps}>
          <TabPanel>
            <FirstVisitAppointment
              isArabic={isArabic}
              unorderedListStyle={unorderedListStyle}
              align={align}
            />
          </TabPanel>
          <TabPanel>
            <FaqLearnMore
              align={align}
              unorderedListStyle={unorderedListStyle}
              isArabic={isArabic}
            />
          </TabPanel>
        </TabNav>
      </div>
    </Layout>
  );
};

export default InstructionsPage;
